import React, { type FC } from 'react';

import LinkComponent from '../../../../../../components/link';
import Styles from './styles.scss';

interface PinterestLinkProps {
	url: string;
	image: string;
	description: string;
}

const PinterestLink: FC<PinterestLinkProps> = (props) => {
	const { url, image, description } = props;
	const shareLink = `https://pinterest.com/pin/create/button?url=${url}&media=${image}&description=${description}`;

	return (
		<div className={Styles.pinterestShareLink}>
			<LinkComponent
				websiteUrl={shareLink}
				icon="social_pinterest"
				title="Pinterest"
			/>
		</div>
	);
};
// }
export default PinterestLink;
