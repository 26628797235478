import Link from '@components/link';
import type Icon from '@xo-union/tk-component-icons';
import React, {
	type ComponentProps,
	type MouseEventHandler,
	useCallback,
	useMemo,
} from 'react';
import onClickOutside from 'react-onclickoutside';
import type { Viewport } from 'types/redux';
import type { Decorated } from 'types/vendor';
import { ShareModal } from './components/share_modal';
import {
	type UseShareModalType,
	useShareModal,
} from './components/share_modal/hooks/use-share-modal';
import SocialLinks from './components/social_links';
import Styles from './styles.scss';

interface Props {
	vendor: Decorated;
	viewport: Viewport;
	icon?: ComponentProps<typeof Icon>['name'];
}

interface BottomAlertProps extends Pick<Props, 'vendor'> {
	closeModal: UseShareModalType['closeModal'];
}

const BottomAlert = onClickOutside(
	({ vendor, closeModal }: BottomAlertProps) => {
		const { claimedStatus } = vendor;
		return (
			<div data-testid="social_sharing_bottom_alert">
				{/* biome-ignore lint/a11y/useKeyWithClickEvents: Click only overlay */}
				<div className={Styles.alertOverlay} onClick={closeModal} />
				<div
					className={
						claimedStatus === 'UNCLAIMED'
							? Styles.bottomAlertTaller
							: Styles.bottomAlert
					}
				>
					<SocialLinks vendor={vendor} />
				</div>
			</div>
		);
	},
);

interface SocialLinksOverlayProps extends Omit<Props, 'icon'> {
	closeModal: UseShareModalType['closeModal'];
	isModalOpen: UseShareModalType['isModalOpen'];
}
const SocialLinksOverlay = ({
	vendor,
	viewport,
	closeModal,
	isModalOpen,
}: SocialLinksOverlayProps) => {
	const isMobile = viewport.isMobile || viewport.lessThan.large;

	if (isMobile) {
		return <BottomAlert vendor={vendor} closeModal={closeModal} />;
	}

	return <ShareModal modalIsOpen={isModalOpen} onClose={closeModal} />;
};

export const SocialSharing = ({ vendor, viewport, icon }: Props) => {
	const { openModal, isModalOpen, closeModal } = useShareModal();
	const linkIcon = useMemo(() => {
		if (icon) {
			return { icon, iconSize: 'md' };
		}

		return { text: 'Share', icon: 'share', iconSize: 'sm' };
	}, [icon]);

	const onClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
		(e) => {
			e.preventDefault();
			openModal();
		},
		[openModal],
	);

	return (
		<>
			<span className={Styles.shareLinkContainer}>
				<Link websiteUrl="#" title="Share" {...linkIcon} onClick={onClick} />
			</span>
			{isModalOpen && (
				<SocialLinksOverlay
					vendor={vendor}
					viewport={viewport}
					closeModal={closeModal}
					isModalOpen={isModalOpen}
				/>
			)}
		</>
	);
};

export default SocialSharing;
