import { Row } from '@xo-union/tk-component-grid';
import React, { FC, useMemo } from 'react';
import { bucketMedia } from './helpers';
import MobileGalleryQuickLinkCollection from './quick_link_collection';

import { useInlineRfqForm } from '../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import Styles from './styles.scss';

interface MobileGalleryQuickLinksProps {
	media: Vendor.Media[];
	context?: ReturnType<typeof useInlineRfqForm>;
}

export const MobileGalleryQuickLinks: FC<MobileGalleryQuickLinksProps> = (
	props,
) => {
	const { media } = props;

	const bucketedMedia = useMemo(() => bucketMedia(media), [media]);
	const filteredBucketedMedia = useMemo(
		() => Array.from(bucketedMedia).filter(([, value]) => value.length),
		[bucketMedia],
	);

	if (filteredBucketedMedia.length < 2) {
		return null;
	}

	return (
		<div className={Styles.quickLinkRow}>
			<MobileGalleryQuickLinkCollection
				mediaBuckets={filteredBucketedMedia}
				{...props}
			/>
		</div>
	);
};
