import { H4 } from '@xo-union/tk-ui-typography';
import React from 'react';
import type { NavItemProps } from '../../containers/nav/types';
import { EmptyStateSearchLink } from '../EmptyStateSearchLink/EmptyStateSearchLink';

import Styles from './AvailabilitySectionEmptyState.scss';

export interface AvailabilitySectionEmptyStateProps extends NavItemProps {
	title: string;
}

export const AvailabilitySectionEmptyState = () => (
	<>
		<H4 className={Styles.sectionTitle}>Availability</H4>
		<EmptyStateSearchLink
			header="No availability details yet"
			text="Looking for the perfect match?"
			linkText="Continue browsing vendors"
			storefrontSection="Availability"
		/>
	</>
);
