import type { Media, PhotoMedia } from '@typings/vendor';

export type OrientationsKey = `${'L' | 'P'}${'L' | 'P'}${'L' | 'P'}${
	| 'L'
	| 'P'}${'L' | 'P'}`;

export const getOrientationsKey = (media: Media[]): OrientationsKey => {
	return (
		(media
			?.map((m) =>
				m.mediaType === 'PHOTO' &&
				(m as PhotoMedia).height > (m as PhotoMedia).width
					? 'P'
					: 'L',
			)
			.join('') as OrientationsKey) || 'LLLLL'
	);
};

export type GridTemplate =
	| 'template1'
	| 'template2'
	| 'template3'
	| 'template4';

export const gridTemplateMap: Partial<Record<OrientationsKey, GridTemplate>> = {
	LPPPP: 'template2',
	LPPPL: 'template2',
	LPPLP: 'template2',
	LPPLL: 'template2',
	LPLPP: 'template3',
	LPLPL: 'template1',
	LPLLP: 'template4',
	LPLLL: 'template1',
	LLPPP: 'template3',
	LLPPL: 'template1',
	LLPLP: 'template1',
	LLPLL: 'template1',
	LLLPP: 'template3',
	LLLPL: 'template1',
	LLLLP: 'template1',
	LLLLL: 'template1',
};

// Image dimensions [width, height] for each grid template
const wide_xl = { width: 630, height: 480 };
const wide = { width: 480, height: 480 };
const full = { width: 280, height: 480 };
const half = { width: 280, height: 240 };

export const gridTemplateImgSizes: Record<
	GridTemplate,
	{ width: number; height: number }[]
> = {
	template1: [wide_xl, half, half, half, half],
	template2: [wide, full, full, half, half],
	template3: [wide, half, half, full, full],
	template4: [wide, full, half, half, full],
};
