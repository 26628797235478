import { createSelector } from 'reselect';
import { PLACEHOLDER_CONSTANTS } from '../../components/Media/constants';

export const selectMedia = (state: Redux.State) =>
	state.vendor.vendorRaw?.mediaSummary.media;

export const selectDerivedMedia = createSelector(
	selectMedia,
	(incomingMedia) => {
		if (!incomingMedia || incomingMedia.length === 0) {
			return null;
		}

		const derivedMedia = incomingMedia.slice();

		while (derivedMedia.length < 5) {
			derivedMedia.push({
				...PLACEHOLDER_CONSTANTS,
				mediaType: 'PHOTO',
			});
		}

		return derivedMedia;
	},
);
