import Icon from '@xo-union/tk-component-icons';
import { delay } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

import Styles from './styles.scss';

export default class CopyLink extends PureComponent {
	static propTypes = {
		url: PropTypes.string.isRequired,
	};

	showNotification = () => {
		ReactTooltip.show(this.refs.linkCopied);
		delay(ReactTooltip.hide, 2000, this.refs.linkCopied);
	};

	render() {
		return (
			<div className={Styles.copyLink}>
				<CopyToClipboard
					text={this.props.url}
					onCopy={this.showNotification}
					data-testid="test-copy-to-clipboard"
				>
					<Icon name="url" size="md" />
				</CopyToClipboard>
				<div ref="linkCopied" data-tip="Link Copied!" />
				<ReactTooltip place="right" type="light" border delayShow={500} />
			</div>
		);
	}
}
