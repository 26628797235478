import Calendar from '@components/calendar/Calendar';
import type { AvailabilityResponse } from '@components/calendar/types';
import { Caption, H4 } from '@xo-union/tk-ui-typography';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React from 'react';
import RfqModal from '../../containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RfqButton from '../../containers/cta_box/RfqButton';
import type { NavItemProps } from '../../containers/nav/types';
import Styles from './AvailabilitySection.scss';

export type AvailabilitySectionProps = {
	availabilityData: AvailabilityResponse;
};

const AvailabilitySection = ({
	availabilityData,
	className,
}: AvailabilitySectionProps & NavItemProps) => {
	const formContext = useInlineRfqForm({
		initiator: 'Availability Calendar CTA',
	});

	const renderRfqButton = () => (
		<RfqButton
			key="availability-rfq-button"
			size="sm"
			color="secondary"
			label="Ask about a specific date"
			handleCTAClick={formContext.openModal}
			styleName={Styles.availabilityRfqButton}
		/>
	);

	const renderLegend = () => (
		<div className={Styles.legendContainer}>
			<div className={Styles.legendIcon} />
			<div>
				<Caption size="lg">Full availability</Caption>
			</div>
			<div className={`${Styles.legendIcon} ${Styles.partial}`} />
			<div>
				<Caption size="lg">Limited availability</Caption>
			</div>
		</div>
	);

	return (
		<div className={classNames(Styles.availabilitySectionContainer, className)}>
			<RfqModal context={formContext} />
			<H4 className={Styles.sectionTitle}>Availability</H4>
			<Calendar availabilityData={availabilityData} />
			{renderLegend()}
			<Body1 className={Styles.sectionCaption}>
				Reach out to this vendor to confirm their availability, as recent
				changes may not be reflected in this calendar{' '}
			</Body1>
			{renderRfqButton()}
		</div>
	);
};

export default AvailabilitySection;
