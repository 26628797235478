import cx from 'classnames';
import React, { type FC } from 'react';
import { connect } from 'react-redux';
import AnalyticsConstants from '../../../../../constants/analytics';
import TrackableLink from '../../../../components/trackable_link';
import VendorAnalytics from '../../../../decorators/vendorAnalytics';
import Phone from './components/phone';

import { useLiteStorefront } from '@hooks/use-lite-storefront';
import Styles from './styles.scss';

export interface ContactButtonsProps {
	className?: string;
	memberId: string;
	vendor: Vendor.Decorated;
	viewport: Redux.Viewport;
}

const ContactButtons: FC<ContactButtonsProps> = (props) => {
	const { className = '', memberId, vendor } = props;

	const { websiteUrl, displayWebsiteUrl, phone, extension } = vendor;
	const url =
		websiteUrl && websiteUrl.length > 0 ? websiteUrl : displayWebsiteUrl;
	const vendorAnalytics = new VendorAnalytics(vendor);
	const isLiteStorefront = useLiteStorefront();

	const trackingData = {
		event: AnalyticsConstants.CLICK_THROUGH_TO_WEBSITE,
		properties: {
			destination: 'website',
			memberId,
			...vendorAnalytics.baseEventProps(),
		},
	};

	return (
		<div className={`${cx(Styles.contactButtons, className)}`}>
			<TrackableLink
				icon="website"
				title="website"
				iconClassName={Styles.webIcon}
				websiteUrl={url}
				trackingData={trackingData}
			/>
			{!isLiteStorefront && <Phone phone={phone} extension={extension} />}
		</div>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	memberId: state.membership.member ? state.membership.member.id : '',
	vendor: state.vendor.vendor,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(ContactButtons);
