import React, { type FC } from 'react';

import CopyLink from '../copy_link';
import EmailLink from '../email_link';
import FacebookLink from '../facebook_link';
import PinterestLink from '../pinterest_link';
import TwitterLink from '../twitter_link';

import Styles from './styles.scss';

interface SocialLinksProps {
	vendor: Vendor.Decorated;
}

const SocialLinks: FC<SocialLinksProps> = (props) => {
	const description = 'I found this great wedding vendor on The Knot!';
	const { storefrontCard, name, uriData } = props.vendor;

	return (
		<div className={Styles.socialLinks}>
			<FacebookLink url={uriData.uri} />
			<PinterestLink
				url={uriData.uri}
				image={storefrontCard.url}
				description={description}
			/>
			<TwitterLink url={uriData.uri} title={name} />
			<EmailLink url={uriData.uri} title={name} />
			<CopyLink url={uriData.uri} />
		</div>
	);
};

export default SocialLinks;
