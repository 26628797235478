import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';
import AnalyticsConstants from '../../../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../../../decorators/vendorAnalytics';

export type SourceContentType =
	| 'photo tile'
	| 'video tile'
	| 'virtual tour tile';

export const getQuickLinkSourceContent = (
	type: Vendor.MediaType,
): SourceContentType => {
	const mediaTypeToText: Record<Vendor.MediaType, SourceContentType> = {
		PHOTO: 'photo tile',
		VIDEO: 'video tile',
		'360TOUR': 'virtual tour tile',
	};

	return mediaTypeToText[type];
};

const useTrackQuickLinksInteraction = (vendor: Vendor.Decorated) => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(sourceContent: SourceContentType) => {
			const paidStatus = vendor.isPaid ? 'paid' : 'unpaid';
			const vendorAnalytics = new VendorAnalytics(vendor);

			track({
				event: AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
				properties: {
					...vendorAnalytics.baseEventProps(),
					sourcePage: `${paidStatus} storefront`,
					action: 'see all',
					portfolioType: AnalyticsConstants.portfolioType.MAIN,
					sourceContent,
				},
			});
		},
		[track, vendor],
	);
};

export default useTrackQuickLinksInteraction;
