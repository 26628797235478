import { useAppSelector } from '@redux/hooks';
import { useMemo } from 'react';
import AnalyticsConstants from '../../../constants/analytics';
import VendorAnalytics from '../../decorators/vendorAnalytics';

export const useBaseStorefrontAnalytics = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor);

	return useMemo(() => {
		if (!vendor) return {};
		const vendorAnalytics = new VendorAnalytics(vendor);
		const sourcePage =
			vendor.purchaseStatus === 'PAID'
				? AnalyticsConstants.PAID_STOREFRONT
				: AnalyticsConstants.UNPAID_STOREFRONT;
		return {
			...vendorAnalytics.baseEventProps(),
			sourcePage,
		};
	}, [vendor]);
};
