import AnalyticsConstants from '@constants/analytics/index.js';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';
import VendorAnalytics from '../../../../../../../decorators/vendorAnalytics';

const useTrackMediaAnalytics = (vendor: Vendor.Decorated | null) => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(index: number, mediaItem: Vendor.Media) => {
			if (!vendor) {
				return;
			}

			const vendorAnalytics = new VendorAnalytics(vendor);
			const sourcePage =
				vendor.purchaseStatus === 'PAID'
					? AnalyticsConstants.PAID_STOREFRONT
					: AnalyticsConstants.UNPAID_STOREFRONT;
			track({
				event: AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
				properties: {
					action: AnalyticsConstants.CLICK_MOSAIC,
					mediaIndex: index,
					mediaType: mediaItem.mediaType,
					item: mediaItem.url,
					sourcePage,
					sourceContent: AnalyticsConstants.MOSAIC,
					portfolioType: AnalyticsConstants.portfolioType.MAIN,
					...vendorAnalytics.baseEventProps(),
				},
			});
			if (mediaItem.mediaType === '360TOUR') {
				track({
					event: AnalyticsConstants.TOUR_INTERACTION,
					properties: {
						action: AnalyticsConstants.CLICK_MOSAIC,
						mediaIndex: index,
						mediaType: mediaItem.mediaType,
						item: mediaItem.url,
						sourcePage,
						sourceContent: AnalyticsConstants.MOSAIC_TILE,
						portfolioType: AnalyticsConstants.portfolioType.MAIN,
						...vendorAnalytics.baseEventProps(),
					},
				});
			}
		},
		[track, vendor],
	);
};

export default useTrackMediaAnalytics;
