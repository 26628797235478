import Icon from '@xo-union/tk-component-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';
import AnalyticsConstants from '../../../../../../../constants/analytics';
import { AnalyticsContext } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import Styles from './styles.scss';

export class Phone extends Component {
	static propTypes = {
		extension: PropTypes.string,
		phone: PropTypes.string.isRequired,
		track: PropTypes.func.isRequired,
		viewport: PropTypes.object.isRequired,
	};

	static defaultProps = {
		extension: '',
		phone: '',
		viewport: {},
	};

	onClickHandler = () => {
		const { vendor, memberId } = this.props;
		const { track } = this.context;
		const { CALL_VENDOR } = AnalyticsConstants;

		const vendorAnalytics = new VendorAnalytics(vendor);

		track({
			event: CALL_VENDOR,
			properties: {
				destination: 'phone',
				userDecisionArea: 'Vendor Info',
				phoneNumber: vendor.phone,
				memberId,
				...vendorAnalytics.baseEventProps(),
			},
		});
	};

	hasPhone() {
		return this.props.phone && this.props.phone.length;
	}

	extension() {
		const { extension } = this.props;
		const sanitizedExtension = !!extension && extension.replace(/\D/g, '');
		return sanitizedExtension.length ? `,${sanitizedExtension}` : '';
	}

	phoneLink() {
		const linkContent = (
			<div>
				<Icon className={Styles.phoneIcon} name="phone" size="md" />
				<span className={Styles.phoneLink}>Call</span>
			</div>
		);

		const { viewport, phone } = this.props;

		if (viewport.isMobile || viewport.lessThan.large) {
			return (
				<a
					href={`tel:+1${phone}${this.extension()}`}
					onClick={this.onClickHandler}
					title="Call"
				>
					{linkContent}
				</a>
			);
		}
		return (
			<Link
				href="#navContact"
				to="navContact"
				smooth
				offset={-50}
				onClick={this.onClickHandler}
				title="Go to contact section"
			>
				{linkContent}
			</Link>
		);
	}

	render() {
		return (
			this.hasPhone() && (
				<div>
					<meta
						content={`tel:+1${this.props.phone}${this.extension()}`}
						itemProp="telephone"
						data-testid="phone-meta"
					/>
					{this.phoneLink()}
				</div>
			)
		);
	}
}

function mapStateToProps(state) {
	return {
		vendor: state.vendor.vendor,
		viewport: state.viewport,
		memberId: state.membership.member ? state.membership.member.id : '',
	};
}

Phone.contextType = AnalyticsContext;

export default connect(mapStateToProps)(Phone);
