import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';
import AnalyticsConstants from '../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';

export type ActionType = 'swipe right' | 'swipe left' | 'see all';

const useTrackCarouselInteraction = (vendor: Vendor.Decorated) => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(action: ActionType, properties?: object) => {
			const paidStatus = vendor.isPaid ? 'paid' : 'unpaid';
			const vendorAnalytics = new VendorAnalytics(vendor);

			track({
				event: AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
				properties: {
					action,
					sourcePage: `${paidStatus} storefront`,
					sourceContent: 'media overview',
					...vendorAnalytics.baseEventProps(),
					...(properties ?? {}),
				},
			});
		},
		[track, vendor],
	);
};

export default useTrackCarouselInteraction;
