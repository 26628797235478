import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { useCallback, useMemo, useState } from 'react';
import type { Decorated } from 'types/vendor';
import AnalyticsConstants from '../../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../../decorators/vendorAnalytics';

export type UseShareModalType = ReturnType<typeof useShareModal>;

export const useShareModal = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const isMobile = useAppSelector(selectIsMobile);
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorAnalytics = new VendorAnalytics(vendor);
	const { track } = useAnalyticsContext();
	const uri = vendor.uriData?.uri;
	const title = vendor.name;
	const shareData: ShareData = { url: uri, title };

	const nativeShare = useMemo(() => {
		if (!isMobile) return false;
		if (!navigator.canShare || !navigator.share) return false;
		return navigator.canShare(shareData);
	}, [isMobile, shareData]);

	const openModal = useCallback(() => {
		track({
			event: AnalyticsConstants.SHARE_STOREFRONT,
			properties: vendorAnalytics.baseEventProps(),
		});

		if (nativeShare && navigator.canShare(shareData))
			return navigator.share(shareData);

		setModalOpen(true);
	}, [nativeShare, shareData, track, vendorAnalytics]);

	const closeModal = useCallback(() => {
		if (nativeShare) return;
		setModalOpen(false);
	}, [nativeShare]);

	return {
		isModalOpen: modalOpen,
		openModal,
		closeModal,
	};
};
