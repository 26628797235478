import type { VendorMediaKey } from '@components/unified-lightbox/atoms';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import classnames from 'classnames';
import React, { type FC } from 'react';
import { connect } from 'react-redux';
import Styles from './Gallery.scss';
import { LiteStorefrontGallery } from './LiteStorefrontGallery';
import GalleryPlaceHolder from './components/GalleryPlaceholder/GalleryPlaceHolder';
import { MediaSummary } from './components/MediaSummary/MediaSummary';
import SeeAllPhotosCTA from './components/SeeAllPhotosCTA/SeeAllPhotosCTA';
import Media from './containers/Media/Media';
import {
	getOrientationsKey,
	gridTemplateImgSizes,
	gridTemplateMap,
} from './grid_templates';
import { selectDerivedMedia } from './selectors';

export interface GalleryProps {
	lazy?: boolean;
	media: Vendor.Media[] | null;
	mediaCount: number;
	onMediaClick?: (index: number) => void;
	onSeeAllClick?: () => void;
	onVirtualTourClick?: () => void;
	openUnifiedLightbox?:
		| ((index: number, filterValue?: VendorMediaKey) => void)
		| null;
}

const Gallery: FC<GalleryProps> = ({
	lazy = false,
	onSeeAllClick,
	onVirtualTourClick,
	onMediaClick,
	openUnifiedLightbox,
	media,
	mediaCount,
}) => {
	const indexedMedia = media?.map((m, index) => ({ index, ...m })) || [];
	const isVirtualTourOnGrid = indexedMedia
		.slice(0, 5)
		.some((element) => element.mediaType === '360TOUR');
	const isVideoOnGrid = indexedMedia
		.slice(0, 5)
		.some(
			(element) =>
				element.mediaType === 'VIDEO' || element.mediaType === 'VIDEO_UPLOAD',
		);
	const orientationsKey = getOrientationsKey(media?.slice(0, 5) || []);
	const gridTemplate = gridTemplateMap[orientationsKey] || 'template1';

	const openUnifiedLightboxVideos = (index: number) => {
		return openUnifiedLightbox?.(index, 'videos');
	};
	const openUnifiedLightboxTours = (index: number) => {
		return openUnifiedLightbox?.(index, 'tours');
	};

	const isLiteStorefront = useLiteStorefront();
	if (isLiteStorefront) {
		return (
			<LiteStorefrontGallery
				media={indexedMedia}
				onMediaClick={openUnifiedLightbox || onMediaClick}
				onSeeAllClick={openUnifiedLightbox || onSeeAllClick}
			/>
		);
	}

	return (
		<div
			data-testid="mosaic-grid"
			className={classnames(Styles.galleryGrid, Styles[gridTemplate])}
		>
			{mediaCount > 0
				? indexedMedia.slice(0, 5).map((mediaItem, index) => {
						return (
							<Media
								key={mediaItem.index}
								lazy={lazy}
								onMediaClick={openUnifiedLightbox || onMediaClick}
								index={mediaItem.index}
								mediaItem={mediaItem}
								width={gridTemplateImgSizes[gridTemplate][index].width}
								height={gridTemplateImgSizes[gridTemplate][index].height}
								smartCrop
								preload
							/>
						);
					})
				: [0, 1, 2, 3, 4].map((i) => <GalleryPlaceHolder key={i} />)}
			<div className={Styles.summary}>
				<MediaSummary
					mediaItems={indexedMedia}
					isVirtualTourOnGrid={isVirtualTourOnGrid}
					isVideoOnGrid={isVideoOnGrid}
					onSeeAllClick={onSeeAllClick}
					onVirtualTourClick={onVirtualTourClick}
					openUnifiedLightboxVideos={openUnifiedLightboxVideos}
					openUnifiedLightboxTours={openUnifiedLightboxTours}
				/>
			</div>
			<div className={Styles.ctaGroup}>
				{mediaCount > 0 && (
					<SeeAllPhotosCTA
						onClick={openUnifiedLightbox || onSeeAllClick}
						count={mediaCount}
					/>
				)}
			</div>
		</div>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	media: selectDerivedMedia(state),
	mediaCount: state.vendor.vendorRaw?.mediaSummary.media?.length || 0,
});

export default connect(mapStateToProps)(Gallery);
