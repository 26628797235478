import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import { openUnifiedLightboxAtom } from '@components/unified-lightbox/atoms';
import { getFilterByMediaType } from '@components/unified-lightbox/helpers';
import useImage from '@hooks/useImage';
import { useSetAtom } from 'jotai';
import React, { type FC, useState } from 'react';
import ImageWrapper from '../../../../../components/Media/ImageWrapper';
import { PLACEHOLDER_CONSTANTS } from '../../../../../components/Media/constants';
import Playable, {
	type PlayableRenderProps,
} from '../../../../../components/Media/playable';
import type { useInlineRfqForm } from '../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { getMediaQuickTileText } from './helpers';
import Styles from './styles.scss';

export interface MobileGalleryQuickLinkItemProps {
	mediaBucket: Vendor.Media[];
	trackQuicklinksClick: (type: string) => void;
	context?: ReturnType<typeof useInlineRfqForm>;
}

export const MobileGalleryQuickLinkItem: FC<MobileGalleryQuickLinkItemProps> = (
	props,
) => {
	const [imageStatus, onLoad, onError] = useImage(PLACEHOLDER_CONSTANTS.url);
	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);

	const { mediaBucket, trackQuicklinksClick, context } = props;
	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	const mediaItem = mediaBucket[0];
	const { mediaType } = mediaItem;

	const tileWidth = 125;
	const tileHeight = 100;

	const placeholderContent = (
		<span className={Styles.quickLinkItemPlaceholderText}>
			{' '}
			Preview Unavailable{' '}
		</span>
	);

	return (
		<Playable
			media={mediaItem}
			render={({ playMedia, stopMedia }: PlayableRenderProps) => {
				const onClick = async () => {
					trackQuicklinksClick(mediaType);
					setOpenUnifiedLightbox({
						indexInAllMedia: 0,
						filter: getFilterByMediaType(mediaType),
					});
					setIsUnifiedLightboxModalOpen(true);
				};

				return (
					<>
						<div
							role="button"
							onClick={onClick}
							onKeyDown={onClick}
							tabIndex={0}
							className={Styles.quickLinkContainer}
						>
							<ImageWrapper
								imageClass={Styles.quickLinksImage}
								placeholderClass={Styles.quickLinkItemPlaceholder}
								placeholderContent={placeholderContent}
								imageStatus={imageStatus}
								mediaItem={mediaItem}
								onError={onError}
								onLoad={onLoad}
								height={tileHeight}
								width={tileWidth}
								smartCrop
							/>
							<span className={Styles.quickLinkText}>
								{getMediaQuickTileText(mediaBucket)}
							</span>
						</div>
						{isUnifiedLightboxModalOpen && (
							<UnifiedLightboxModal
								entryPoint="Quicklinks"
								isModalOpen={isUnifiedLightboxModalOpen}
								closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
							/>
						)}
					</>
				);
			}}
		/>
	);
};
