import React from 'react';
import type { FCWithChildren } from 'types/react-extended';
import { PLACEHOLDER_CONSTANTS } from '../../../../components/Media/constants';
import Styles from '../../Gallery.scss';

interface MediaWrapperProps {
	index: number;
	onClick: () => void;
	mediaItem: Vendor.Media;
}

export const MediaWrapper: FCWithChildren<MediaWrapperProps> = ({
	children,
	index,
	mediaItem,
	onClick,
}) => {
	if (
		mediaItem.id === PLACEHOLDER_CONSTANTS.id ||
		mediaItem.url === PLACEHOLDER_CONSTANTS.url
	) {
		return (
			<div
				className={Styles.photoButton}
				data-testid={`photo-button-placeholder-${index}`}
			>
				{children}
			</div>
		);
	}

	return (
		<button
			aria-label={`Gallery image ${index + 1}`}
			className={Styles.photoButton}
			onClick={onClick}
			onKeyPress={onClick}
			tabIndex={0}
			type="button"
		>
			{children}
		</button>
	);
};
