import { useAppSelector } from '@redux/hooks';
import { inboxUrl } from '@settings';

export const useConversationUrl = (vendorId: string | undefined) => {
	const conversations = useAppSelector(
		(state) => state.messagedVendors.conversations,
	);

	if (!vendorId || Object.keys(conversations).length <= 0) return undefined;

	const conversationId = conversations[vendorId];

	if (!conversationId) return undefined;

	return `${inboxUrl}/${conversationId}`;
};
