import { useAppSelector } from '@redux/hooks';
import React, { type FC } from 'react';
import type { Decorated } from 'types/vendor';
import MediaComponent from '../../../../components/Media';
import Playable, {
	type PlayableRenderProps,
} from '../../../../components/Media/playable';
import { MediaWrapper } from '../../components/MediaWrapper/MediaWrapper';
import useTrackMediaAnalytics from './hooks/use-track-media-analytics';

export interface MediaContainerProps {
	height: number;
	index: number;
	lazy?: boolean;
	smartCrop?: boolean;
	mediaItem: Vendor.Media;
	preload?: boolean;
	width: number;
	onMediaClick?: (index: number) => void;
}

export const Media: FC<MediaContainerProps> = (props) => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const { index, onMediaClick, ...mediaProps } = props;
	const trackMediaAnalytics = useTrackMediaAnalytics(vendor);

	const handleMediaClick = () => {
		trackMediaAnalytics(index, mediaProps.mediaItem);
	};
	return (
		<Playable
			media={mediaProps.mediaItem}
			render={({ playMedia }: PlayableRenderProps) => {
				const onClick = () => {
					if (onMediaClick) {
						onMediaClick(index);
						trackMediaAnalytics(index, mediaProps.mediaItem);
					} else {
						playMedia();
						handleMediaClick();
					}
				};

				return (
					<MediaWrapper
						mediaItem={mediaProps.mediaItem}
						index={index}
						onClick={onClick}
					>
						<MediaComponent {...mediaProps} />
					</MediaWrapper>
				);
			}}
		/>
	);
};

export default Media;
