import { usePdfUrl } from '@components/pricing/hooks/use-pdf-url';
import { PricingPdf } from '@components/pricing/pdf';
import { useAppSelector } from '@redux/hooks';
import { Body1, H3 } from '@xo-union/tk-ui-typography';
import React, { type FC } from 'react';
import type { NavItemProps } from '../../containers/nav/types';
import Styles from './PricingSection.scss';
import PricingSecondaryCTA from './components/PricingSecondaryCTA/PricingSecondaryCta';

const PricingSection: FC<NavItemProps> = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const pdfUrl = usePdfUrl();
	const conversations = useAppSelector(
		(state) => state.messagedVendors.conversations,
	);

	if (vendor === null) {
		return null;
	}

	const conversationStarted = conversations[vendor.id];

	return (
		<>
			<H3 className={Styles.title}>Pricing</H3>
			{conversationStarted && !pdfUrl ? (
				<Body1>Get in touch for details about pricing.</Body1>
			) : (
				<div className={Styles.pdfContainer}>
					<PricingPdf
						text={'Browse sample services and estimates:'}
						className={Styles.pdf}
					/>
					<PricingSecondaryCTA conversationStarted={conversationStarted} />
				</div>
			)}
		</>
	);
};

export default PricingSection;
