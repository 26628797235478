import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Link from '../../../../../../components/link';
import Styles from './styles.scss';

class FacebookLink extends PureComponent {
	render() {
		const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${this.props.url}`;

		return (
			<div className={Styles.facebookShareLink}>
				<Link websiteUrl={shareLink} icon="social_facebook" title="Facebook" />
			</div>
		);
	}
}

FacebookLink.propTypes = {
	url: PropTypes.string.isRequired,
};

export default FacebookLink;
