import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Link from '../../../../../../components/link';
import Styles from './styles.scss';

export default class EmailLink extends PureComponent {
	static propTypes = {
		url: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
	};

	render() {
		const { url, title } = this.props;
		const shareLink = `mailto:someone@example.com?Subject=From%20The%20Knot:%20${title}&body=${url}`;

		return (
			<div className={Styles.emailShareLink}>
				<Link websiteUrl={shareLink} icon="email" title="Email" />
			</div>
		);
	}
}
