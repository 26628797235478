import React, { type FC } from 'react';

import LinkComponent from '../../../../../../components/link';
import Styles from './styles.scss';

interface TwitterLinkProps {
	url: string;
	title: string;
}

const TwitterLink: FC<TwitterLinkProps> = (props) => {
	const { title, url } = props;
	const shareLink = `https://twitter.com/intent/tweet?text=Check%20out%20${title}&url=${url}&via=theknot`;

	return (
		<div className={Styles.twitterShareLink}>
			<LinkComponent
				websiteUrl={shareLink}
				icon="social_twitter"
				title="Twitter"
			/>
		</div>
	);
};

export default TwitterLink;
