import * as React from 'react';

export const PhotosIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Photos Icon</title>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.53317 6.4665V1.53317H6.4665V6.4665H1.53317ZM0.666504 0.999837C0.666504 0.815742 0.815742 0.666504 0.999837 0.666504H6.99984C7.18393 0.666504 7.33317 0.815742 7.33317 0.999837V6.99984C7.33317 7.18393 7.18393 7.33317 6.99984 7.33317H0.999837C0.815742 7.33317 0.666504 7.18393 0.666504 6.99984V0.999837ZM9.53317 6.4665V1.53317H14.4665V6.4665H9.53317ZM8.6665 0.999837C8.6665 0.815742 8.81574 0.666504 8.99984 0.666504H14.9998C15.1839 0.666504 15.3332 0.815742 15.3332 0.999837V6.99984C15.3332 7.18393 15.1839 7.33317 14.9998 7.33317H8.99984C8.81574 7.33317 8.6665 7.18393 8.6665 6.99984V0.999837ZM1.53317 9.53317V14.4665H6.4665V9.53317H1.53317ZM0.999837 8.6665C0.815742 8.6665 0.666504 8.81574 0.666504 8.99984V14.9998C0.666504 15.1839 0.815742 15.3332 0.999837 15.3332H6.99984C7.18393 15.3332 7.33317 15.1839 7.33317 14.9998V8.99984C7.33317 8.81574 7.18393 8.6665 6.99984 8.6665H0.999837ZM9.53317 14.4665V9.53317H14.4665V14.4665H9.53317ZM8.6665 8.99984C8.6665 8.81574 8.81574 8.6665 8.99984 8.6665H14.9998C15.1839 8.6665 15.3332 8.81574 15.3332 8.99984V14.9998C15.3332 15.1839 15.1839 15.3332 14.9998 15.3332H8.99984C8.81574 15.3332 8.6665 15.1839 8.6665 14.9998V8.99984Z"
				fill="black"
			/>
		</svg>
	);
};
