import type { IconName } from '@xo-union/dist-iconography';
import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import React from 'react';

import Styles from '../../styles.scss';

interface Props {
	url?: string;
	text: string;
	icon: IconName;
	image?: string;
}

export const ShareLink = ({ url, text, icon, image = '' }: Props) => {
	let shareLink = url;

	if (image) {
		shareLink = `https://pinterest.com/pin/create/button?url=${url}&media=${image}&description=${text}`;
	} else if (text === 'Facebook') {
		shareLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
	} else if (text === 'Twitter') {
		shareLink = `https://twitter.com/intent/tweet?text=Check%20out%20${text}&url=${url}&via=theknot`;
	} else if (text === 'Email') {
		shareLink = `mailto:?subject=Check out this vendor on The Knot&body=I thought you might be interested in this vendor on The Knot: ${url}`;
	}

	return (
		<a
			href={shareLink}
			target="_blank"
			rel="noopener noreferrer nofollow"
			className={Styles.shareLink}
		>
			<Icon name={icon} size="md" className={Styles.shareLinkImage} />
			<Body1>{text}</Body1>
		</a>
	);
};
